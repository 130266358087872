<template>

</template>

<script>
import AuthService from '../auth';
import urls from "@/router/urls";
import store from "@/store";

export default {
  name: 'AutoLogin',

  data() {
    return {
      loginForm: {
        data: {
          token: null,
        },
        errors: {
          data: {},
          global: [],
        },
        inProcessing: false,
      },
    };
  },

  created() {
    try {
      this.isLoggingOut = true;

      // AuthService.logout();

      this.autologin()

    } catch (e) {
      //
    } finally {
      this.isLoggingOut = false;
    }
  },

  methods: {
    async autologin() {
      this.loginForm.errors.global = [];

      this.loginForm.inProcessing = true;
      try {

        const data = await this.$axios.get(urls.urlInsaleslogin + this.$store.getters.loginParams);

        location = data.data.url;

      } catch (e) {
        if (e.response) {
          if (e.response.status === 403) {
            this.loginForm.errors.global.push('Аккаунт заблокирован');
          } else if (e.response.status === 404) {
            this.loginForm.errors.global.push('Неверный E-mail или пароль');
          }
        }
        console.error('error', e)
      } finally {
        this.loginForm.inProcessing = false;
      }
    },
  },
};
</script>
